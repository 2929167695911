import { CalendarOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { BookingItemTransmitted, LoxoneCredentials, TenantCustomizationInfo } from "../../utils/types";
import { CollapsibleAccessCode, CollapsibleLoxoneCredentials } from "../Registration/Regform";
import {
  DEFAULT_APP_LOGO_URL,
  DEFAULT_APP_PRIVACY_PAGE_URL,
  DEFAULT_APP_PROPERTY_RULES_PAGE_URL
} from "../../constant/app.constant";
import { getTenantCustomizationInfoByTechId } from "../../service/tenantCustomization";
import { useTranslation } from "react-i18next";

const ItemCard = ({
  bookingItem,
  backendBaseUrl,
  directionLink
}: {
  bookingItem: BookingItemTransmitted;
  backendBaseUrl: string;
  directionLink: string;
}) => {
  const { t } = useTranslation();
  const checkInTime = new Date(bookingItem.checkInTime._seconds! * 1000).toLocaleDateString();
  const checkOutTime = new Date(bookingItem.checkOutTime._seconds * 1000).toLocaleDateString();
  const [tenantCustomizationInfo, setTenantCustomizationInfo] = useState<TenantCustomizationInfo>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initTenantCustomizationInfo();
  }, []);

  const initTenantCustomizationInfo = async () => {
    if (!tenantCustomizationInfo) {
      try {
        setTimeout(() => {
          setIsLoading(true);
        }, 1);
        const result = await getTenantCustomizationInfoByTechId(backendBaseUrl, bookingItem.propertyTechId);
        setTenantCustomizationInfo(result);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <p className="loading-text">Loading...</p>
        </div>
      )}
      <div className="guest-data">
        <div className="booking-info">
          {tenantCustomizationInfo?.logoUrl && (
            <a href="https://jobelhome.com">
              <img
                className="success-logo"
                src={tenantCustomizationInfo?.logoUrl ?? DEFAULT_APP_LOGO_URL}
                onError={event => {
                  event.currentTarget.src = DEFAULT_APP_LOGO_URL;
                  event.currentTarget.onerror = null;
                }}
                alt="Jobelhome logo"
              />
            </a>
          )}
          <p className="dashboard-guest-data">
            Name: {bookingItem.guests[0].firstName} {bookingItem.guests[0].lastName}
          </p>
          <p className="dashboard-guest-data">
            Booked dates: <CalendarOutlined style={{ fontSize: 20 }} />
            {checkInTime}-{checkOutTime}
          </p>
          <p className="dashboard-guest-data">
            Number of guests:
            <div className="icons-div">
              {Array.from({ length: bookingItem.guestNumber }, (_, i) => (
                <span className="array-span" key={i}>
                  <UserOutlined style={{ fontSize: 20 }} />
                </span>
              ))}
            </div>
            {bookingItem.guestNumber}
          </p>
          <p className="dashboard-guest-data">Booked Apartment: {tenantCustomizationInfo?.propertyDisplayName}</p>

          {accessCodeModule(bookingItem.itemBookingId, backendBaseUrl)}

          <div className="dashboard-direction-div">
            <a href={directionLink} target="_blank" rel="noreferrer">
              <button type="button" className="direction-btn">
                Your direction
              </button>
            </a>
          </div>
          <div className="dashboard-direction-div">
            <p className="dashboard-guest-data">Reference number: #{bookingItem.itemBookingId}</p>
          </div>

          <div className="privacy-info hyper-link-guest-dashboard">
            {tenantCustomizationInfo?.privacyPolicyUrl && (
              <p className="apartment-rules-p">
                {t("registration.privacy-first-part")} <br />
                <a
                  className="inline-link"
                  href={tenantCustomizationInfo?.privacyPolicyUrl ?? DEFAULT_APP_PRIVACY_PAGE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("registration.privacy-second-part")}
                </a>
              </p>
            )}
            {tenantCustomizationInfo?.propertyRulesUrl && (
              <p className="apartment-rules-p">
                {t("registration.house-rules-first-part")} <br />
                <a
                  className="inline-link"
                  href={tenantCustomizationInfo?.propertyRulesUrl ?? DEFAULT_APP_PROPERTY_RULES_PAGE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("registration.house-rules-second-part")}
                </a>
                {t("registration.house-rules-third-part")}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function accessCodeModule(bookingId: string, backendUrl: string) {
  return <CollapsibleAccessCode bookingId={bookingId} backendUrl={backendUrl} />;
}

export default ItemCard;
