import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { BookingItemTransmitted, TenantCustomizationInfo } from "../../utils/types";
import "./PropertyStyle.css";
import { CollapsibleGuests } from "./Regform";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_APP_LOGO_URL,
  DEFAULT_APP_PRIVACY_PAGE_URL,
  DEFAULT_APP_PROPERTY_RULES_PAGE_URL
} from "../../constant/app.constant";
import { getTenantCustomizationInfoByTechId } from "../../service/tenantCustomization";
import { getIsRequiredGuestProperties } from "../../utils/validation";

const PropertyDisplay = ({
  bookingItems,
  i,
  setBookingItems,
  propertyImages,
  backendBaseUrl
}: {
  i: number;
  bookingItems: BookingItemTransmitted[];
  setBookingItems: any;
  propertyImages: string[];
  backendBaseUrl: string;
}) => {
  const { t, i18n } = useTranslation();
  const bookingItem: BookingItemTransmitted = bookingItems[i];
  const [tenantCustomizationInfo, setTenantCustomizationInfo] = useState<TenantCustomizationInfo>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initTenantCustomizationInfo();
  }, []);

  const initTenantCustomizationInfo = async () => {
    if (!tenantCustomizationInfo) {
      try {
        setTimeout(() => {
          setIsLoading(true);
        }, 1);
        const result = await getTenantCustomizationInfoByTechId(backendBaseUrl, bookingItem.propertyTechId)
        setTenantCustomizationInfo(result)
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="property-wrapper">
      {isLoading && (
        <div className="loading-overlay">
          <p className="loading-text">Loading...</p>
        </div>
      )}
      <div className="property-card">
        <Carousel
          className="carousel-main"
          width={350}
          transitionTime={1}
          swipeable={true}
          stopOnHover={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          emulateTouch={true}
          interval={4000}
          showArrows={true}
          showStatus={false}
          showIndicators={true}
        >
          {propertyImages &&
            propertyImages.map((url: string) => (
              <div className="carousel-item">
                <img src={url} alt="Property" />
              </div>
            ))}
        </Carousel>
      </div>
      <section className="inline-data">
        <HomeOutlined className="property-icon" style={{ fontSize: 20 }} />
        <h5 className="h5-reg-info">{tenantCustomizationInfo?.propertyDisplayName}</h5>
        {Array.from({ length: bookingItem.guestNumber }, (_, i) => (
          <span key={i}>
            <UserOutlined style={{ fontSize: 20 }} />
          </span>
        ))}
        <p className="guestnumber-details">{bookingItem.guestNumber}</p>
      </section>
      <div className="header-data">
        {tenantCustomizationInfo?.logoUrl && (
          <img
            className="logo"
            src={tenantCustomizationInfo?.logoUrl ?? DEFAULT_APP_LOGO_URL}
            onError={event => {
              event.currentTarget.src = DEFAULT_APP_LOGO_URL;
              event.currentTarget.onerror = null;
            }}
            alt=""
          />
        )}
        <p className="regpage-welcome">
          {i18n.resolvedLanguage === "de" ? (
            <>{t("registration.welcome-first-part")}</>
          ) : (
            <>
              {t("registration.welcome-first-part")} {bookingItems[0] ? bookingItems[0].guests[0].firstName : ""}
            </>
          )}
          <br />
          {t("registration.welcome-second-part")}{" "}
          {tenantCustomizationInfo?.propertyDisplayName ?? "JobelHome Apartments!"}
          <br />
        </p>
        {/*<section className="inline-data">*/}
        {/*  {Array.from({ length: guestNumber }, (_, i) => (*/}
        {/*    <span key={i}>*/}
        {/*        <UserOutlined style={{ fontSize: 20 }} />*/}
        {/*      </span>*/}
        {/*  ))}*/}
        {/*  <p className="guestnumber-details">{guestNumber}</p>*/}
        {/*</section>*/}
        <div>
          {bookingItem.checkInTime ? new Date(bookingItem.checkInTime._seconds * 1000).toLocaleDateString() : ""}-
          {bookingItem.checkOutTime ? new Date(bookingItem.checkOutTime._seconds * 1000).toLocaleDateString() : ""}
        </div>
      </div>
      {[...Array(bookingItem.guests.length).keys()].map(j => (
        <CollapsibleGuests
          key={j}
          bookingItems={bookingItems}
          i={i}
          j={j}
          setBookingItems={setBookingItems}
          isRequiredGuestProperties={getIsRequiredGuestProperties(bookingItem.propertyTechId, j)}
        />
      ))}
      <div className="privacy-info">
        {tenantCustomizationInfo?.privacyPolicyUrl && (
          <p className="apartment-rules-p">
            {t("registration.privacy-first-part")} <br />
            <a
              className="inline-link"
              href={tenantCustomizationInfo?.privacyPolicyUrl ?? DEFAULT_APP_PRIVACY_PAGE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("registration.privacy-second-part")}
            </a>
          </p>
        )}
        {tenantCustomizationInfo?.propertyRulesUrl && (
          <p className="apartment-rules-p">
            {t("registration.house-rules-first-part")} <br />
            <a
              className="inline-link"
              href={tenantCustomizationInfo?.propertyRulesUrl ?? DEFAULT_APP_PROPERTY_RULES_PAGE_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("registration.house-rules-second-part")}
            </a>
            {t("registration.house-rules-third-part")}
          </p>
        )}
      </div>
    </div>
  );
};

export default PropertyDisplay;
